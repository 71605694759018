import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Companie ce dezvoltă soluții complete<br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Proiectare",
                                            "Implementare",
                                            "Testare",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Suntem o echipă dedicată de profesioniști în domeniul dezvoltării software și web, pasionați de inovație și de transformarea digitală a lumii contemporane. La e-consulting, credem că viitorul tehnologiei este decentralizat și plin de oportunități inovatoare, iar misiunea noastră este să dezvoltăm soluții software care să îi împuternicească pe clienți în această lume digitală dinamică. Cu ani de experiență în spatele nostru și o înțelegere profundă a tehnologiei web și a dezvoltării software, oferim servicii personalizate pentru a vă ajuta să vă atingeți obiectivele digitale.</p>
                                <p> Ne angajăm să oferim informații clare și actualizate, să vă ghidăm în luarea deciziilor informate și să vă sprijinim în atingerea obiectivelor digitale. Cu echipa noastră experimentată și soluțiile inovatoare pe care le oferim, suntem aici pentru a vă ajuta să vă dezvoltați și să vă gestionați prezența online cu încredere într-un mediu digital în continuă schimbare.



</p>
                                <div className="read-more-btn mt--40">
                                <Link className="btn-default btn-icon" to="/about-us">Mai multe despre noi</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
