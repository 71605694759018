import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Ce este o aplicație web?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    O aplicație web este un software care rulează pe server și este accesat de utilizatori printr-un browser web. Acesta poate fi un site web static sau dinamic, o platformă de comerț electronic, un sistem de management al conținutului sau o aplicație web complexă. Aplicațiile web sunt esențiale pentru a oferi o experiență digitală interactivă și personalizată, fiind utilizate în diverse domenii, de la comerț și divertisment, până la educație și sănătate.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Diferență între aplicații web și site-uri web?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    Principala diferență între aplicațiile web și site-urile web este funcționalitatea și interactivitatea. Site-urile web sunt formate din pagini web statice sau dinamice care oferă informații și conținut, în timp ce aplicațiile web sunt mai complexe și oferă funcționalități interactive, cum ar fi formulare, căutare, autentificare, gestionare de conținut, etc. În plus, aplicațiile web sunt mai personalizate și pot fi adaptate nevoilor specifice ale utilizatorilor, în timp ce site-urile web sunt mai generale și orientate către conținut.
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                   Ce este testarea software?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                    Testarea software este un proces esențial în dezvoltarea de produse software, care constă în evaluarea calității și funcționalității acestora. Scopul testării software este de a identifica erorile, defectele și problemele de performanță ale aplicațiilor, pentru a asigura că acestea funcționează corect și sunt conforme cu cerințele și așteptările utilizatorilor. 
                
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    De ce este importantă prezența online?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    Prezența online este esențială pentru orice afacere sau organizație care dorește să-și promoveze produsele și serviciile, să-și extindă audiența și să-și crească vizibilitatea și credibilitatea. O prezență online puternică și bine gestionată poate oferi o serie de beneficii, cum ar fi accesibilitatea 24/7, marketing și promovare eficiente, interacțiune și comunicare cu clienții, creșterea vânzărilor și a veniturilor, precum și consolidarea brandului și a reputației.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

