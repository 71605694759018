import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Acasă</Link>
                
            </li>
            
            <li><Link to="/about-us">Despre noi</Link></li>

            <li><Link to="/pricing">Ofertă</Link></li>
                                    

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
