import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";



const PricingFive = () => {
    
    return (
        <div className="row row--15">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan simplu</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">€</span>
                                    <span className="price">20</span>
                                </div>
                                <span className="subtitle">Euro pe lună</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Analiză 2 Cryptomonede din portofoliul nostru </li>
                                <li><FiCheck /> Sugestii investiții</li>
                                <li><FiCheck /> 2 analize lunare ale portofoliului</li>
                                <li><FiCheck /> Informații la zi cu evoluția pieței</li>
                                <li><FiCheck /> Asistență online</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <Link className="btn-default btn-border" to={`/contact/`}>Cumpără acum </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan Avansat</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">€</span>
                                    <span className="price">50</span>
                                </div>
                                <span className="subtitle">Euro pe lună</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Analiză 5 Cryptomonede din portofoliul nostru </li>
                                <li><FiCheck /> Sugestii investiții și plan de investiții</li>
                                <li><FiCheck /> 4 analize lunare ale portofoliului</li>
                                <li><FiCheck /> Informații la zi cu evoluția pieței</li>
                                <li><FiCheck /> Asistență online și telefonică</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <Link className="btn-default" to={`/contact/`}>Cumpără acum </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan Premium</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">€</span>
                                    <span className="price">200</span>
                                </div>
                                <span className="subtitle">Euro pe lună</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Toate din planul avansat plus</li>
                                <li><FiCheck /> Analize constante ale portofoliului</li>
                                <li><FiCheck /> Opțiunea de a investi in numele tău</li>
                                <li><FiCheck /> Prioritate în aflarea noutăților</li>
                                <li><FiCheck /> Asistență 24/7 online și telefonică</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                        <Link className="btn-default btn-border" to={`/contact/`}>Cumpără acum </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

        </div>
    )
}
export default PricingFive;
