import React from 'react'
import logostartup from '../../assets/images/startnat.png'

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a href="#">Politica de confindențialitate</a></li>
                                <li><a href="#">Termeni și condiții</a></li>
                                <li><a href="/contact">Contactează-ne</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right d-flex justify-content-end align-items-center text-center text-md-end">
                            <p style={{ marginRight: '5%' }} className="copyright-text">© e-consulting.ro {new Date().getFullYear()}</p>
                            <img style={{ width: '37%', height: 'auto' }} src={logostartup} alt="logo" />
                            
                            

                                                      
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default Copyright;