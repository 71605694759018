import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import ServiceFive from "../elements/service/ServiceFive";
import Copyright from '../common/footer/Copyright';

const AboutUs = () => {
    return (
        <>
            <SEO title="Despre noi || e-consulting.ro" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        {/* <h3 className="rn-sub-badge"><span className="theme-gradient">Our Company's About Details.</span></h3> */}
                                    </div>
                                    <h1 className="title display-one">Suntem o companie <br /> Cu profesioniști dedicați.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">
                                    Suntem o companie software, lucrând pentru a-ți urmări interesele.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">
                                Suntem o echipă pasionată de inovație și tehnologie, dedicată să aducă soluții digitale de ultimă generație în lumea afacerilor. La e-consulting, ne concentrăm pe consultanță, implementare și testare pentru a vă ajuta să vă atingeți obiectivele digitale. <br />
                                <br />

Consultanță: Cu o experiență vastă în domeniul tehnologic, oferim consultanță specializată pentru a vă ghida în alegerea celor mai bune soluții pentru afacerea dvs. Ne concentrăm pe înțelegerea nevoilor dvs. specifice și vă oferim strategii personalizate pentru a maximiza eficiența și performanța.<br /><br />

Implementare soluții: Suntem experți în dezvoltarea și implementarea soluțiilor software personalizate, inclusiv aplicații web, site-uri web și aplicații mobile. Cu o abordare orientată către calitate și inovație, lucrăm îndeaproape cu dvs. pentru a vă oferi produse digitale de înaltă calitate, adaptate nevoilor dvs. unice.<br /><br />

Testing: Punem un accent deosebit pe asigurarea calității și fiabilității produselor noastre. Efectuăm teste riguroase pentru a identifica și corecta orice defecte potențiale, asigurându-ne că produsele noastre sunt livrate cu cea mai înaltă calitate.<br /><br />

Suntem aici pentru a vă oferi soluții digitale de încredere și inovatoare. Împreună, putem transforma viziunile dvs. în realitate digitală și să construim împreună viitorul digital al afacerii dvs.

Alăturați-vă nouă și descoperiți puterea tehnologiei în creștere alături de e-consulting!</p>

                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "CU CE TE PUTEM AJUTA"
                                    title = "Serviciile pe care le oferim."
                                    description = "In funcție de necesitățile tale, iți oferim servicii de consultanță, testare, modificare si implementare a soluțiilor software."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div>

                {/* Start Brand Area  */}
                {/* <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div> */}
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <AboutFour image="./images/about/contact-image.png" />
                
                {/* Start Elements Area  */}
                {/* <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div> */}
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div> */}
                {/* End Elements Area  */}






                {/* <FooterFour /> */}
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
